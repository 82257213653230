import React from 'react';
import { Layout, SEO } from 'components/common';

export default () => (
  <Layout>
    <SEO title="404: Not found" location="/404" />
    <h1>Stranica Nije Pronađena</h1>
    <p>Upravo ste pronašli stranicu koja ne postoji... paradoks.</p>
  </Layout>
);
